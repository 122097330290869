module controllers {
    export module master {
        interface IPermitUpdateControllerScope extends ng.IScope {
        }

        interface IPermitUpdateParams extends ng.ui.IStateParamsService {
            permId: number;
        }

        export class permitUpdateController {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'permitService',
                'countryService',
                '$state',
                '$stateParams',
                "documentRepositoryService",
                "unitOfMeasureService",
                "currencyService",
                "supplierService",
                "consigneeService",
                "productService",
                "uiGridConstants",
                "$transitions"
            ];

            permId: number = 0;
            permit: interfaces.master.IPermit;
            breadCrumbDesc: string;

            selectedTab: number = 0;
            documentRepository: interfaces.documentManagement.IDocumentRepository;

            gridApi: uiGrid.IGridApi;
            gridConsigneeApi: uiGrid.IGridApi;
            gridQuotaApi: uiGrid.IGridApi;
            gridProductsApi: uiGrid.IGridApi;
            gridQuotaAllocationApi: uiGrid.IGridApi;

            public permStatus: interfaces.master.IStatusData;
            showAll: boolean = false;

            permitHook: any;

            constructor(private $scope: IPermitUpdateControllerScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private permitService: interfaces.master.IPermitService,
                private countryService: interfaces.applicationcore.ICountryService,
                private $state: ng.ui.IStateService,
                private $stateParams: IPermitUpdateParams,
                private documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private supplierService: interfaces.master.ISupplierService,
                private consigneeService: interfaces.master.IConsigneeService,
                private productService: interfaces.master.IProductService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                public $transitions: ng.ui.core.ITransition
            ) {
                if ($stateParams.permId == null) {
                    return;
                }
                this.permId = $stateParams.permId;

                this.permitHook = $transitions.onSuccess({
                    to: 'auth.MasterData.Permit.Update',
                    from: 'auth.MasterData.Permit.Update.**'
                }, () => {                  
                        return this.loadPermit();
                });


                this.loadPermit();
            }

            loadPermit() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        return this.permitService.getPermitDetail().get({
                            permId: this.permId,
                        }, (result: interfaces.master.IPermit) => {
                            this.permit = result;
                            this.breadCrumbDesc = this.permit.PermitDrop.Display;


                            this.gvwSupplier.data = result.SupplierList;
                            this.gvwConsignee.data = result.ConsigneeList;
                            this.gvwQuota.data = result.QuotaList;
                            this.gvwQuotaAllocation.data = result.QuotaAllocationList;

                            if (this.permit.QuotaTypeClassificationValueId === 740 || this.permit.QuotaTypeClassificationValueId === 798){
                                this.gvwQuotaAllocation.columnDefs[5].visible = true;      
                                this.gvwQuotaAllocation.columnDefs[6].visible = true;                           
                                this.gvwQuotaAllocation.columnDefs[7].visible = true;       
                                this.gvwQuotaAllocation.columnDefs[10].visible = false;          
                                this.gvwQuota.columnDefs[6].visible = true;
                                this.gvwQuota.columnDefs[7].visible = true;
                            }
                            else{
                                this.gvwQuotaAllocation.columnDefs[5].visible = false;   
                                this.gvwQuotaAllocation.columnDefs[6].visible = false;                           
                                this.gvwQuotaAllocation.columnDefs[7].visible = false;         
                                this.gvwQuotaAllocation.columnDefs[11].visible = false; 
                                this.gvwQuota.columnDefs[6].visible = false;
                                this.gvwQuota.columnDefs[7].visible = false;                                                           
                            }

                            if (this.permit.QuotaTypeClassificationValueId === 739 || this.permit.QuotaTypeClassificationValueId === 798){
                                this.gvwQuotaAllocation.columnDefs[8].visible = true;         
                                this.gvwQuotaAllocation.columnDefs[9].visible = true;          
                                this.gvwQuotaAllocation.columnDefs[10].visible = true;                        
                                this.gvwQuotaAllocation.columnDefs[11].visible = true;       
                                this.gvwQuota.columnDefs[8].visible = true;
                                this.gvwQuota.columnDefs[9].visible = true;                                                                               
                            }
                            else{
                                this.gvwQuotaAllocation.columnDefs[8].visible = false;   
                                this.gvwQuotaAllocation.columnDefs[9].visible = false;     
                                this.gvwQuotaAllocation.columnDefs[10].visible = false;                                              
                                this.gvwQuotaAllocation.columnDefs[11].visible = false;           
                                this.gvwQuota.columnDefs[8].visible = false;
                                this.gvwQuota.columnDefs[9].visible = false;                                
                            }                            

                            this.gvwProducts.data = result.ProductsList;

                            this.gvwSupplier.totalItems = result.SupplierList.length;
                            this.gvwConsignee.totalItems = result.ConsigneeList.length;
                            this.gvwQuota.totalItems = result.QuotaList.length;
                            this.gvwQuotaAllocation.totalItems = result.QuotaAllocationList.length;
                            this.gvwProducts.totalItems = result.ProductsList.length;

                            this.$timeout(() => {
                                this.getDocuments(this.showAll);
                                this.loadStatus();
                            });

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            loadUoM() {
                if (this.permit) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.permit.Entity.Id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadStatus() {
                this.permStatus = {
                    StatusName: this.permit.StatusName,
                    Id: this.permit.Id,
                    EntityId: this.permit.Entity.Id,
                    CurrentStatusId: this.permit.StatusId,
                    CurrentStatusComments: this.permit.StatusLastComments,
                    CurrentStatusStampDate: this.permit.StatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.permit.StatusLastUpdateStampFullName,
                    CurrentStatusType: Enum.EnumStatusType.Permit,
                    EnumStatusType: Enum.EnumStatusType.Permit,
                    EnumStatusAuthorisationType: Enum.EnumStatusType.Permit,
                    IsEditable: true
                };
            }

            updateStatus = (newStatusId: number, comments: string) => {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.permit.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        return this.savePermit().then((data) => {
                           if (!data.HasErrorMessage){
                                 this.permitService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'permit.update'
                                    }, () => {
                                        this.loadPermit();
                                        this.selectedTab = 5;
                                        return;
                                        });
                                    this.generalService.displayMessageHandler(data);
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                });
                            }
                        }, () => {
                            return;
                        });

                });
            };

            getDocuments(showAll:boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.permit.PermitDrop.Code, systemType: Enum.EnumSystemType.Permit, ownerEntityId: this.permit.Entity.Id, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {
                this.$state.go("auth.MasterData.Permit.Update.Documents", { permId: this.permit.Id });
            }

            showRestrictionDetail() {
                this.$state.go("auth.MasterData.Permit.Update.RestrictionDetail", { parId: this.permit.Restriction.Id });
            }

            public gvwSupplier: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'PermitSupplierList.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ADD",
                    displayName: "",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 56,
                    cellTemplate: `
                    <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.permitUpdateController.DeleteSupplier(row.entity)" class="btn btn-warning btn-sm" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                Delete
                            </button>
                    </div>`,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.permitUpdateController.addInlineSuppliers()" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                }, {
                    name: "Supplier",
                    displayName: "* Supplier",
                    field: "Supplier",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="Supplier-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadSuppliers(searchText)" pagination-search="true" refresh-delay="1000"  ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"></gts-dropdown>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                }, {
                    name: "Comments",
                    displayName: "Comments",
                    field: "Comments",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="Comments-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2" />
                            </p>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                }, {
                    name: "Active",
                    displayName: "Active",
                    field: "IsActive",
                    width: 100,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.permitUpdateController.setRowDirtySupplier(row.entity)"  ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">',
                }, ]
            };

            countryChange(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.IPermitSupplierDisplay) {
                entity.Supplier = model;
            }

            loadSuppliers(searchText: string) {
                var defered = this.$q.defer();

                if (!this.permit) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.supplierService.getSupplierDropdown(this.permit.Entity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            addInlineSuppliers() {
                var supItem : interfaces.master.IPermitSupplierDisplay = {
                    Id: 0,
                    Supplier: null,
                    Comments: "",
                    IsActive: true,
                };

                (<Array<any>>(this.gvwSupplier.data)).splice(0, 0, supItem);
            }

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.$timeout(() => {
                    this.gvwSupplier.data = this.permit.SupplierList;
                });
            }

            DeleteSupplier(entity: interfaces.master.IPermitSupplierDisplay) {
                if(entity.Id === 0) {
                    _.remove(this.gvwSupplier.data, (o) => {
                        return o === entity;
                    });

                    var removeArr: Array<interfaces.master.IPermitSupplierDisplay> = [];
                    removeArr.push(entity);
                    this.gridApi.rowEdit.setRowsClean(removeArr);
                    return;
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        var paramsObject = {
                            permSupplierId: entity.Id,
                        };
                        return this.permitService.deleteSupplier().delete(paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'permit.update'
                            }, () => {
                                this.loadPermit();
                                return;
                                });
                            this.generalService.displayMessageHandler(data);
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            setRowDirtySupplier(entity) {
                if(this.gridApi) {
                    this.gridApi.rowEdit.setRowsDirty([entity]);
                }
            }

            //Consignee
            public gvwConsignee: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterExcelFilename: 'PermitConsigneeList.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD'],
                onRegisterApi: (gridApi) => { this.registerConsigneeGridApi(gridApi) },
                columnDefs: [{
                    name: "ADD",
                    displayName: "",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 56,
                    cellTemplate: `
                    <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.permitUpdateController.DeleteConsignee(row.entity)" class="btn btn-warning btn-sm"  ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                Delete
                            </button>
                    </div>`,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.permitUpdateController.addInlineConsignees()"  ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                }, {
                    name: "Consignee",
                    displayName: "* Consignee",
                    field: "Consignee",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="Consignee-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadConsignees(searchText)" pagination-search="true" refresh-delay="1000" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"></gts-dropdown>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                }, {
                    name: "Comments",
                    displayName: "Comments",
                    field: "Comments",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="Comments-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2" />
                            </p>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                }, {
                    name: "Active",
                    displayName: "Active",
                    field: "IsActive",
                    width: 100,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.permitUpdateController.setRowDirtyConsignee(row.entity)" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">',
                }, ]
            };

            loadConsignees(searchText: string) {
                var defered = this.$q.defer();

                if (!this.permit) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.consigneeService.getDropdownList(this.permit.Entity.Id, searchText).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            addInlineConsignees() {
                var conItem : interfaces.master.IPermitConsigneeDisplay = {
                    Id: 0,
                    Consignee: null,
                    Comments: "",
                    IsActive: true,
                };

                (<Array<any>>(this.gvwConsignee.data)).splice(0, 0, conItem);
            }

            public registerConsigneeGridApi(gridApi: uiGrid.IGridApi) {
                this.gridConsigneeApi = gridApi;

                this.$timeout(() => {
                    this.gvwConsignee.data = this.permit.ConsigneeList;
                });
            }

            DeleteConsignee(entity: interfaces.master.IPermitConsigneeDisplay) {
                if (entity.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'permit.update'
                    },
                        () => {
                            var paramsObject = {
                                permConsigneeId: entity.Id,
                            };
                            return this.permitService.deleteConsignee().delete(paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'permit.update'
                                }, () => {
                                    this.loadPermit();
                                    return;
                                    });
                                this.generalService.displayMessageHandler(data);
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        });
                } else {
                    _.remove(this.gvwConsignee.data, (o) => {
                        return o === entity;
                    });

                    var removeArr: Array<interfaces.master.IPermitConsigneeDisplay> = [];
                    removeArr.push(entity);
                    this.gridConsigneeApi.rowEdit.setRowsClean(removeArr);
                }
            }

            setRowDirtyConsignee(entity) {
                if(this.gridConsigneeApi) {
                    this.gridConsigneeApi.rowEdit.setRowsDirty([entity]);
                }
            }

            //Quota Allocation
            public gvwQuotaAllocation: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterExcelFilename: 'PermitQuotaAllocationList.xlsx',
                gridMenuShowHideColumns: false,
                enableCellEditOnFocus: true,
                paginationPageSizes: [10, 20, 50, 100],
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD'],
                onRegisterApi: (gridApi) => { this.registerQuotaAllocationGridApi(gridApi) },
                columnDefs: [{
                    name: "ADD",
                    displayName: "",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 56,
                    cellTemplate: `
                    <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.permitUpdateController.DeleteQuotaAllocation(row.entity)" class="btn btn-warning btn-sm" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                Delete
                            </button>
                    </div>`,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.permitUpdateController.addInlineQuotaAllocation()" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false,
                }, {
                    name: "QuotaType",
                    displayName: "* Quota Type",
                    field: "QuotaType",
                    width: 220,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="QuotaType-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadQuotaAllocationTypes()" pagination-search="true" refresh-delay="1000" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"></gts-dropdown>
                        </form>`,
                }, {
                    name: "HSCode",
                    displayName: "HS Code",
                    field: "HSCode",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="HSCode-{{MODEL_COL_FIELD.$$hashKey}}" required="row.entity.QuotaType.Id === 749" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor  ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"/>
                            </p>
                        </form>`,
                }, {
                    name: "ProductCategory",
                    displayName: "Product Category",
                    field: "ProductCategory",
                    width: 320,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Code}} {{MODEL_COL_FIELD.Description}}">{{MODEL_COL_FIELD.Code}} <span ng-if="MODEL_COL_FIELD"> - </span> {{MODEL_COL_FIELD.Description}}</div>`,
                    editableCellTemplate: `                
                            <gts-product-category  ng-click="grid.appScope.permitUpdateController.setRowDirtyQuotaAllocation(row.entity)"  name="ProductCat-{{MODEL_COL_FIELD.$$hashKey}}" selected-product-category="row.entity.ProductCategory" is-single-select="true" required="row.entity.QuotaType.Id === 750"  ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"></gts-product-category>
                            `,
                    cellEditableCondition: (scope) => { if(scope.row.entity.QuotaType.Id === 750){return true;} else {return false;}  },
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue) {
                                cellValue.Display = cellValue.Code + ' - '+cellValue.Description;
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                }, {
                    name: "Product",
                    displayName: "Product",
                    field: "Product",
                    width: 220,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="Product-{{MODEL_COL_FIELD.$$hashKey}}" required="row.entity.QuotaType.Id === 751" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadProducts(searchText)" pagination-search="true" refresh-delay="1000" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"></gts-dropdown>
                        </form>`,
                },                 {
                    name: "QuotaPeAllocatedValue",
                    displayName: "Pre-Allocated Value",
                    field: "PreAllocatedValue",
                    width: 100,
                    visible: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    enableCellEdit: false,
                },
                {
                    name: "Value",
                    displayName: "Value",
                    field: "Value",
                    width: 100,
                    visible:true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="number" name="Amount-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" step="0.01" min="0.1" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"/>
                            </p>
                        </form>`,
                },
                {
                    name: "ValueBalance",
                    displayName: "Value Balance",
                    field: "ValueBalance",
                    width: 100,
                    visible: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false,
                },
                {
                    name: "PreAllocatedAmount",
                    displayName: "Pre-Allocated Amount",
                    field: "PreAllocatedAmount",
                    width: 100,
                    visible: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    enableCellEdit: false,
                },
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    width: 100,
                    visible:true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="number" name="Amount-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" step="0.01" min="0.1" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"/>
                            </p>
                        </form>`,
                }, {
                    name: "UnitOfMeasure",
                    displayName: "Unit Of Measure",
                    field: "UnitOfMeasure",
                    width: 220,
                    visible: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="UnitOfMeasure-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadUoM()" pagination-search="true" refresh-delay="1000" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2"></gts-dropdown>
                        </form>`,
                }, {
                    name: "Balance",
                    displayName: "Balance",
                    field: "Balance",
                    width: 100,
                    visible: true,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false,
                }, {
                    name: "Active",
                    displayName: "Active",
                    field: "IsActive",
                    width: 100,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.permitUpdateController.setRowDirtyQuotaAllocation(row.entity)" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">',
                    editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 2">
                                </p>
                            </form>`,
                }, ]
            };

            addInlineQuotaAllocation() {
                var quotaAllocationItem : interfaces.master.IPermitQuotaAllocationDisplay = {
                    Id: 0,
                    QuotaType: <interfaces.applicationcore.IDropdownModel>{
                        Id: 0,
                        Code: "",
                        Description: "",
                        Display: "",
                        Selected: true,
                    },
                    HSCode: "",
                    ProductCategory: null,
                    Product: <interfaces.applicationcore.IDropdownModel>{
                        Id: 0,
                        Code: "",
                        Description: "",
                        Display: "",
                        Selected: true,
                    },
                    Value: 0,
                    ValueBalance: 0,
                    Amount: 0,
                    PreAllocatedAmount: 0,
                    PreAllocatedValue: 0,
                    UnitOfMeasure: <interfaces.applicationcore.IDropdownModel>{
                        Id: 0,
                        Code: "",
                        Description: "",
                        Display: "",
                        Selected: true,
                    },
                    Balance: 0,
                    IsActive: true,
                };

                (<Array<any>>(this.gvwQuotaAllocation.data)).splice(0, 0, quotaAllocationItem);
            }

            public registerQuotaAllocationGridApi(gridApi: uiGrid.IGridApi) {
                this.gridQuotaAllocationApi = gridApi;
            }

            setRowDirtyQuotaAllocation(entity) {
                if(this.gridQuotaAllocationApi) {
                    this.gridQuotaAllocationApi.rowEdit.setRowsDirty([entity]);
                }
            }

            loadQuotaAllocationTypes() {
                return this.permitService.GetDropdownQuotaType().query({
                }).$promise;
            }

            //DeleteQuotaAllocation
            DeleteQuotaAllocation(entity: interfaces.master.IPermitQuotaAllocationDisplay) {
                if(entity.Id === 0) {
                    _.remove(this.gvwQuotaAllocation.data, (o) => {
                        return o === entity;
                    });

                    var removeArr: Array<interfaces.master.IPermitQuotaAllocationDisplay> = [];
                    removeArr.push(entity);
                    this.gridQuotaAllocationApi.rowEdit.setRowsClean(removeArr);
                    return;
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        var paramsObject = {
                            permQuotaAllocationId: entity.Id,
                        };
                        return this.permitService.deleteQuotaAllocation().delete(paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'permit.update'
                            }, () => {
                                this.loadPermit();
                                return;
                                });
                            this.generalService.displayMessageHandler(data);
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            //Quota
            public gvwQuota: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterExcelFilename: 'PermitQuotaList.xlsx',
                gridMenuShowHideColumns: false,
                enableCellEditOnFocus: true,
                paginationPageSizes: [10, 20, 50, 100],
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD'],
                onRegisterApi: (gridApi) => { this.registerQuotaGridApi(gridApi) },
                columnDefs: [{
                    name: "ADD",
                    displayName: "",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.permitUpdateController.addInlineQuota()" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false,
                }, {
                    name: "TransactionDate",
                    displayName: "Transaction Date",
                    field: "TransactionDate",
                    width: 118,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false,
                }, {
                    name: "TransactionType",
                    displayName: "* TransactionType",
                    field: "TransactionType",
                    width: 220,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}" ng-disabled="row.entity.newOne === false">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="TransactionType-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" ng-disabled="row.entity.newOne === false || grid.appScope.permitUpdateController.permit.LockdownType >= 3"></gts-dropdown>
                        </form>`,
                }, {
                    name: "QuotaAllocation",
                    displayName: "* Quota Allocation",
                    field: "QuotaAllocation",
                    width: 220,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="QuotaAllocation-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadQuotaAllocations(searchText)" pagination-search="true" refresh-delay="1000" ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3"></gts-dropdown>
                        </form>`,
                }, {
                    name: "Reference1",
                    displayName: "* Reference 1",
                    field: "Reference1",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" ng-disabled="row.entity.TransactionType.Id !== 747" name="Reference1-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3"/>
                            </p>
                        </form>`,
                }, {
                    name: "Reference2",
                    displayName: "Reference 2",
                    field: "Reference2",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" ng-disabled="row.entity.TransactionType.Id !== 747" name="Reference2-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3"/>
                            </p>
                        </form>`,
                }, 
                {
                    name: "PreAllocatedValue",
                    displayName: "* Pre-Allocated Value",
                    field: "PreAllocatedValue",
                    width: 100,
                    visible: `(grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 740 || grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 798)`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="number" ng-disabled="row.entity.TransactionType.Id !== 747" name="Value-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3" />
                            </p>
                        </form>`,
                },
                {
                    name: "Value",
                    displayName: "* Value",
                    field: "Value",
                    width: 100,
                    visible: `(grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 740 || grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 798)`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="number" ng-disabled="row.entity.TransactionType.Id !== 747" name="Value-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3" />
                            </p>
                        </form>`,
                }, 
                {
                    name: "PreAllocatedAmount",
                    displayName: "* Pre-Allocated Amount",
                    field: "PreAllocatedAmount",
                    width: 100,
                    visible: `(grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 739 || grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 798)`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                    <form name = "inputForm">
                        <p class="input-group-sm">
                            <input type="number" ng-disabled="row.entity.TransactionType.Id !== 747" name="Value-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3" />
                        </p>
                    </form>`,
                },
                {
                    name: "Amount",
                    displayName: "* Amount",
                    field: "Amount",
                    width: 100,
                    visible: `(grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 739 || grid.appScope.permitUpdateController.permit.QuotaTypeClassificationValueId === 798)`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                    <form name = "inputForm">
                        <p class="input-group-sm">
                            <input type="number" ng-disabled="row.entity.TransactionType.Id !== 747" name="Value-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="grid.appScope.permitUpdateController.permit.LockdownType >= 3" />
                        </p>
                    </form>`,
                }, {
                    name: "Comments",
                    displayName: "Comments",
                    field: "Comments",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" ng-disabled="row.entity.TransactionType.Id !== 747 || grid.appScope.permitUpdateController.permit.LockdownType >= 3" name="Comments-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                            </p>
                        </form>`,
                }, ]
            };

            addInlineQuota() {
                var quotaItem : interfaces.master.IPermitQuotaDisplay = {
                    Id: 0,
                    TransactionDate: null,
                    TransactionType: <interfaces.applicationcore.IDropdownModel>{
                        Id: 747,
                        Code: "",
                        Description: "",
                        Display: "Manual Transaction",
                        Selected: true,
                    },
                    QuotaAllocation: null,
                    Reference1: "",
                    Reference2: "",
                    Value: 0,
                    Amount:0,
                    IsPreAllocation: false,
                    PreAllocatedAmount: 0,
                    PreAllocatedValue: 0,
                    Comments: "",
                    newOne: false,
                };

                (<Array<any>>(this.gvwQuota.data)).splice(0, 0, quotaItem);
            }

            public registerQuotaGridApi(gridApi: uiGrid.IGridApi) {
                this.gridQuotaApi = gridApi;
            }

            loadQuotaAllocations(searchText: string) {
                if (this.permit) {
                    return this.permitService.GetDropdownForQuotaAllocation().query({ 
                        permitId: this.permit.Id,
                        searchText: searchText,
                     }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            //Products
            public gvwProducts: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                enableCellEditOnFocus: false,
                useExternalSorting: false,
                multiSelect: false, 
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'PermitProductList.xlsx',
                gridMenuShowHideColumns: false,
                paginationPageSizes: [10, 20, 50, 100],
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD'],
                onRegisterApi: (gridApi) => { this.registerProductsGridApi(gridApi) },
                columnDefs: [{
                    name: "ADD",
                    displayName: "",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 56,
                    cellTemplate: `
                        <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.permitUpdateController.DeleteProduct(row.entity)" class="btn btn-warning btn-sm" ng-disabled="!grid.appScope.permitUpdateController.permit.HasProductRight">
                                    Delete
                                </button>
                        </div>`,
                    headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.permitUpdateController.addInlineProducts()" ng-disabled="!grid.appScope.permitUpdateController.permit.HasProductRight">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`,
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "Product",
                    displayName: "* Product",
                    field: "Product",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-dropdown ui-select-wrap name="Product-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.permitUpdateController.loadProducts(searchText)" pagination-search="true" refresh-delay="1000" ng-disabled="!grid.appScope.permitUpdateController.permit.HasProductRight"></gts-dropdown>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    enableFiltering: true,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }}
                }, {
                    name: "CoO",
                    displayName: "CoO",
                    field: "CoO",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`                    
                }, {
                    name: "TariffCode",
                    displayName: "Tariff Code",
                    field: "TariffCode",
                    width: 150,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`                    
                }, {
                    name: "Comments",
                    displayName: "Comments",
                    field: "Comments",
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="Comments-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ng-disabled="!grid.appScope.permitUpdateController.permit.HasProductRight"/>
                            </p>
                        </form>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`                    
                }, {
                    name: "Active",
                    displayName: "Active",
                    field: "IsActive",
                    width: 100,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.permitUpdateController.checkBoxChanged(row.entity)" ng-disabled="!grid.appScope.permitUpdateController.permit.HasProductRight"/>',
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor ng-disabled="!grid.appScope.permitUpdateController.permit.HasProductRight"/>
                            </p>
                        </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, ]
            };

            public registerProductsGridApi(gridApi: uiGrid.IGridApi) {
                this.gridProductsApi = gridApi;

                this.$timeout(() => {
                    this.gvwProducts.data = this.permit.ProductsList;
                });
            }

            loadProducts(searchText: string) {
                if (this.permit) {
                    return this.productService.getDropdownList(this.permit.Entity.Id, searchText).query(() => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                }
            }

            addInlineProducts() {
                var productItem : interfaces.master.IPermitProductsDisplay = {
                    Id: 0,
                    EntityCode: "",
                    Product: null,
                    CoO: "",
                    TariffCode: "",
                    Comments: "",
                    IsActive: true,
                };

                (<Array<any>>(this.gvwProducts.data)).splice(0, 0, productItem);
            }

            DeleteProduct(entity: interfaces.master.IPermitProductsDisplay) {
                if(entity.Id === 0) {
                    _.remove(this.gvwProducts.data, (o) => {
                        return o === entity;
                    });

                    var removeArr: Array<interfaces.master.IPermitProductsDisplay> = [];
                    removeArr.push(entity);
                    this.gridProductsApi.rowEdit.setRowsClean(removeArr);
                    return;
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        var paramsObject = {
                            permProductId: entity.Id,
                        };
                        return this.permitService.deleteProduct().delete(paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'permit.update'
                            }, () => {
                                this.loadPermit();
                                return;
                                });
                            this.generalService.displayMessageHandler(data);
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            checkBoxChanged(entity) {
                if (this.gridProductsApi) {
                    this.gridProductsApi.rowEdit.setRowsDirty([entity]);
                }
            }

            controlQuotaChanged() {
                this.permit.AlertOnQuota = this.permit.IsQuotaControlled;
            }

            savePermit() {
                //Supplier Dirty Rows
                if (this.gridApi) {
                    this.permit.SupplierList = _.map(this.gridApi.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.permit.SupplierList = [];
                }

                //Consignee Dirty Rows
                if (this.gridConsigneeApi && this.gridConsigneeApi.rowEdit.getDirtyRows().length > 0) {
                    this.permit.ConsigneeList = _.map(this.gridConsigneeApi.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                } else {
                    this.permit.ConsigneeList = [];
                }

                //Quota Dirty Rows
                if (this.gridQuotaApi) {
                    this.permit.QuotaList = _.map(this.gridQuotaApi.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.permit.QuotaList = [];
                }

                //Quota Allocation Dirty Rows
                if (this.gridQuotaAllocationApi) {
                    this.permit.QuotaAllocationList = _.map(this.gridQuotaAllocationApi.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.permit.QuotaAllocationList = [];
                }

                //Products Dirty Rows
                if (this.gridProductsApi) {
                    this.permit.ProductsList = _.map(this.gridProductsApi.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.permit.ProductsList = [];
                }

               return this.permitService.savePermit().save((this.permit), (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);
                    
                    if (!result.HasErrorMessage) {
                        this.loadPermit();
                        if (this.gridApi) {
                            this.gridApi.rowEdit.setRowsClean(this.permit.SupplierList);
                        }
    
                        if (this.gridConsigneeApi) {
                            this.gridConsigneeApi.rowEdit.setRowsClean(this.permit.ConsigneeList);
                        }
    
                        if (this.gridQuotaApi) {
                            this.gridQuotaApi.rowEdit.setRowsClean(this.permit.QuotaList);
                        }
    
                        if (this.gridQuotaAllocationApi) {
                            this.gridQuotaAllocationApi.rowEdit.setRowsClean(this.permit.QuotaAllocationList);
                            this.gridQuotaAllocationApi.core.refresh();
                        }
    
                        if (this.gridProductsApi) {
                            this.gridProductsApi.rowEdit.setRowsClean(this.permit.ProductsList);
                        }
                    }
                    
                }, (errorResult) => {
                    this.generalService.displayMessageHandler(errorResult.data);
                }).$promise;
            }

            runPermitAllocationBasedOnTariffCode() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        return this.permitService.savePermit().save(this.permit, (result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result);
                            
                            this.permitService.RunPermitAllocationBasedOnTariffCode(this.permId).save({
                                permId: this.permId,
                            }, (result) => {
                                this.generalService.displayMessageHandler(result);
                                this.loadPermit();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }, (errorResult) => {
                            this.generalService.displayMessageHandler(errorResult.data);
                        });
                    });
            }

            createNewVersion() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'permit.update'
                },
                    () => {
                        return this.permitService.savePermit().save(this.permit, (result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result);
                            
                            this.permitService.createNewVersion().save({
                                id: this.permId,
                            }, (result) => {
                                this.generalService.displayMessageHandler(result);
                                this.loadPermit();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }, (errorResult) => {
                            this.generalService.displayMessageHandler(errorResult.data);
                        });
                    });
            }

            saveClosePermit() {
                this.permitService.savePermit().save(this.permit, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);
                    this.$state.go("^");
                }, (errorResult) => {
                    this.generalService.displayMessageHandler(errorResult.data);
                });
            }

            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("permitUpdateController", controllers.master.permitUpdateController);
    }
}